import React from 'react'
import { graphql } from 'gatsby'

import HcpdFormPageHoc from '../../components/HealthCareProviderDirectory/HcpdFormPageHoc'
import EnterNewPasswordForm from '../../components/Auth/EnterNewPasswordForm'
import { ProvideAuth } from '../../components/Auth/useAuth'

export const query = graphql`
  {
    prismicHcpdNewPasswordPage {
      data {
        body_text {
          richText
        }
        title {
          text
        }
      }
    }
  }
`

const UpdatePasswordPage = ({ data }) => {
  const doc = data.prismicHcpdNewPasswordPage.data
  if (!doc) {
    return null
  }

  return (
    <ProvideAuth>
      <HcpdFormPageHoc doc={doc}>
        <EnterNewPasswordForm />
      </HcpdFormPageHoc>
    </ProvideAuth>
  )
}

export default UpdatePasswordPage
