import React from 'react'
import { useForm } from 'react-hook-form'

import SubmitButton from './SubmitButton'
import { RegionContainer } from '../styles/Utility.styles'
import {
  AuthForm,
  FormContainer,
  FormHeader,
  FieldGroup,
} from './styles/AuthFormCommon.styles'
import LabeledTextField from './LabeledTextField'
import { VALIDATION_RULES } from './validationRules'

const EnterNewPasswordForm = () => {
  const { handleSubmit, register, watch, errors } = useForm()
  const onSubmit = values => console.log(values)

  return (
    <RegionContainer>
      <FormContainer>
        <FormHeader>Enter New Password</FormHeader>
        <AuthForm onSubmit={handleSubmit(onSubmit)}>
          <FieldGroup>
            <LabeledTextField
              label="New Password"
              type="password"
              name="password"
              isRequired
              fieldWidth="47%"
              errors={errors.password}
              fieldRef={register(VALIDATION_RULES.password)}
            />
            <LabeledTextField
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              isRequired
              fieldWidth="47%"
              errors={errors.confirmPassword}
              fieldRef={register({
                ...VALIDATION_RULES.password,
                validate: value =>
                  value === watch('password') ? true : 'Passwords do not match',
              })}
            />
          </FieldGroup>
          <SubmitButton type="submit" text="Update Password" />
        </AuthForm>
      </FormContainer>
    </RegionContainer>
  )
}

export default EnterNewPasswordForm
